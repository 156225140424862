import React from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import { isMobile } from "react-device-detect"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      color: "#000",
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      flexBasis: "33.33%",
      flexShrink: 0,
      color: "#000",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: "#000",
    },
  })
)

const Drawers = ({ people }: any) => {
  const classes = useStyles({})
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div>
      {people.map(({ html, frontmatter: { name, title, headshot } }, i) => (
        <ExpansionPanel
          expanded={expanded === `panel${i + 1}`}
          onChange={handleChange(`panel${i + 1}`)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${i + 1}bh-content`}
            id={`panel${i + 1}bh-header`}
          >
            <Typography className={classes.heading} style={{ color: "black" }}>
              {name}
            </Typography>
            <Typography
              className={classes.secondaryHeading}
              style={{ color: "black" }}
            >
              {title}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <img
              src={headshot}
              style={{
                width: "100px",
                height: "100px",
                marginRight: "24px",
                borderRadius: "50%",
              }}
              alt={name}
            />
            <ExpansionPanelBody dangerouslySetInnerHTML={{ __html: html }} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  )
}

export default Drawers

const ExpansionPanelBody = styled.div`
  p {
    color: black;
    font-size: 0.9em;
  }
`
