import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import BkgImg from "gatsby-background-image"
import {
  Title,
  AppBody,
  Content,
  headerImageStyle,
  ContentContainer,
} from "../components/common"
import Drawers from "../components/Drawers"

const AboutUs = ({
  data: {
    headerImage,
    markdownRemark: { html: __html },
    allMarkdownRemark: { nodes },
  },
}) => {
  useEffect(() => {
    console.log(nodes)
  })
  return (
    <Layout>
      <SEO title="About Us" />
      <AppBody>
        <BkgImg
          fluid={headerImage.childImageSharp.fluid}
          style={headerImageStyle}
        >
          <Title>About Us</Title>
        </BkgImg>
        <ContentContainer>
          <Content dangerouslySetInnerHTML={{ __html }} />
          <Content >
            <Drawers people={nodes} style={{background: "transparent"}} />
            <h1>Community Service</h1>
            <p>
              Plains Dedicated takes pride in giving back to the community. We
              have allocated a portion of our profits to give back to the
              community. We are honored to support the following organizations:
            </p>
            <img
              src="/assets/charities.png"
              alt="charities"
              title="charities"
            ></img>
          </Content>
        </ContentContainer>
      </AppBody>
    </Layout>
  )
}

export const query = graphql`
  {
    headerImage: file(relativePath: { eq: "truck3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: "/about-us" } }) {
      html
    }
    allMarkdownRemark(
      filter: { frontmatter: { content_type: { eq: "Person" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        html
        frontmatter {
          order
          name
          headshot
          jobtitle
        }
      }
    }
  }
`

export default AboutUs
